<template>
	<PageLayout :title="$t('deposit.title')">
		<div class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="'neteller.svg'" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="'USD'"
				:form-error="formErrors['amount']"
				:current-method="currentMethod"
				@set-button-value="setButtonValue"
			/>

			<div class="small-width">
				<promocode
					:form-promo-value="form.promocode"
					:form-error="formErrors['promocode']"
					@set-promo-value="setPromoValue"
				/>
			</div>

			<button type="button" class="button primary-button deposit center" @click="pay()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>

		<div class="method-description-cashier">
			{{ $t(`deposit.method-descriptions.neteller`) }}
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositNeteller',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				promocode: null,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('neteller');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		async createTransaction(amount, currency, origin) {
			const response = await apiClient.netellerCreateTransaction(this.walletId, amount, currency, origin);
			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async pay() {
			this.loading = true;

			try {
				const redirectOrigin = window.location.origin;
				const currency = 'USD';
				const { link } = await this.createTransaction(this.form.amount, currency, redirectOrigin);

				if (this.isEmbedded) {
					window.open(link, '_blank');
					const walletId = this.$route.params.walletId;
					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: { status: 'external' },
					});
				} else {
					window.location.replace(link);
				}
			} catch (e) {
				if (e.response?.status === 422 && e.response?.data?.errors) {
					e.response.data.errors.map((value) => {
						this.formErrors[value.param] = value.msg;
					});
				} else {
					const walletId = this.$route.params.walletId;
					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: { status: 'error' },
					});
				}
				console.error('Failed to request payment', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
